import React, {Component} from 'react';
import Logo from '../assets/images/pphh-logo-white-2.svg';
import {Link} from 'gatsby';

class ThankYouPage extends Component {

  render() {
    return (
      <>
        <section className="thank-you">
          <div className="thank-you__wrapper">
            <img className="img-fluid" src={Logo} alt="PPHH Barbers"/>

            <h1>Thanks for your interest.</h1>

            <p>We’ll be in touch soon.</p>

            <Link className='btn' to="/">Back to PP Barbers</Link>
          </div>
        </section>
      </>
    );
  }
}

export default ThankYouPage;
